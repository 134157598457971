
<template>
    <div class="container">
    <div class="row mt-4">
  <div class="col-sm-12 col-md-6 col-lg-4 mb-4"><div class="card card-has-bg click-col" style="background-image: url(../assets/runoff/thai-school-rain.png); background-size: cover;">
          <img class="card-img d-none" src="../assets/runoff/thai-school-rain.png" alt="Creative Manner Design Lorem Ipsum Sit Amet Consectetur dipisi?">
          <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
              <small class="card-meta mb-2 text-dark">Math + Earth Science</small>
              <h4 class="card-title mt-0 "><a class="text-dark" href="/school-intro">Flooding Solutions: School Edition</a></h4>
            <small><i class="far fa-clock"></i> Explore local problems related to water runoff and design solutions to minimize flooding at a local school.</small>
            </div>
            <div class="card-footer">
              <h4>Grades 4-6</h4>
            </div>
          </div>
        </div></div>
      <div class="col-sm-12 col-md-6 col-lg-4 mb-4"><div class="card card-has-bg click-col">
          <img class="card-img d-none" src="" alt="Creative Manner Design Lorem Ipsum Sit Amet Consectetur dipisi?">
          <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
              <small class="card-meta mb-2 text-dark">Math + Earth Science</small>
              <h4 class="card-title mt-0 "><a class="text-dark" href="/intro-runoff">Flooding Solutions: Global Edition</a></h4>
            <small><i class="far fa-clock"></i> Explore the impact of heavy rains at a global level. Examine human impacts and how they can minimize or create more flooding.</small>
            </div>
            <div class="card-footer">
              <h4>Grades 4-6</h4>
            </div>
          </div>
        </div></div>
    <!-- <div class="col-sm-12 col-md-6 col-lg-4 mb-4"><div class="card text-dark card-has-bg click-col">
          <img class="card-img d-none" src="" alt="Creative Manner Design Lorem Ipsum Sit Amet Consectetur dipisi?">
          <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
              <small class="card-meta mb-2">Thought Leadership</small>
              <h4 class="card-title mt-0 "><a class="text-dark" herf="">Design Studio Lorem Ipsum Sit Amet Consectetur dipisi?</a></h4>
            <small><i class="far fa-clock"></i> October 15, 2020</small>
            </div>
            <div class="card-footer">
            </div>
          </div>
        </div></div> -->
    
  </div>
    
  </div>


</template>

<style scoped>
body{
/* Created with https://www.css-gradient.com */
/* background: #161616; */
}

.lead{
  color:#000;
}

.wrapper{margin:10vh}
/* post card styles */

.card{
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
 overflow:hidden;
 border-radius:20px;
 min-height:300px;
   box-shadow: 0 0 12px 0 rgba(0,0,0,0.2);

 @media (max-width: 768px) {
  min-height:350px;
}

@media (max-width: 420px) {
  min-height:300px;
}

 &.card-has-bg{
 transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background-size:120%;
  background-repeat:no-repeat;
  background-position: center center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);}

  &:hover {
    transform: scale(0.98);
     box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
    background-size:130%;
     transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
     color: black;

    .card-img-overlay {
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
      background: rgb(255,186,33);
     background: linear-gradient(0deg, rgb(255,244,155) 0%, rgb(255,225,70) 100%);
     }
  }
}
 .card-footer{
  background: none;
   border-top: none;
    .media{
     img{
       border:solid 3px rgba(255,255,255,0.3);
     }
   }
 }
  .card-title{font-weight:800}
 .card-meta{color:rgba(0,0,0,0.3);
  text-transform:uppercase;
   font-weight:500;
   letter-spacing:2px;}
 .card-body{ 
   transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
 

  }
 &:hover {
   .card-body{
     margin-top:30px;
     transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
   }
 cursor: pointer;
 transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
 .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
 background: rgb(255,186,33);
background: linear-gradient(0deg, rgb(116, 214, 128) 0%, rgb(55, 139, 41) 100%);
}
}
@media (max-width: 767px){
  
}
</style>