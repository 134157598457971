<template>
  <div class="container">
  <h2 class="mt-4">Investigating the Impact of Local Flooding</h2>
  <lesson-card
    :lessonNames="names"
    :description="description"
    :route="route"
  ></lesson-card>
  </div>
</template>

<script>
import LessonCard from "@/components/LessonCard.vue";
export default {
  name: "Local Adventure Menu",
  components: {
    LessonCard,
  },
  data() {
    return {
      names: ["Understand the Problem", "Explore Rainfall Impact", "Test Regional Solutions", "Present Your Findings" ],
      description: ["Learn More About the Impact of Flooding", "Explore Different Surface Materials","Test Local Designs to Minimize Flooding", "Present Your School Flooding Solution"],
      route: ["School Intro", "Debug", "Playground", "Intro Runoff"],
      // engineering: true,
    };
  },
};
</script>