<template>
   <div class="p-4 mb-5">
    <!-- Rounded tabs -->
    <ul id="myTab" role="tablist" class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
      <li class="nav-item flex-sm-fill">
        <button
        class="nav-link border-0 text-uppercase font-weight-bold active"
        id="instructions-tab"
        data-bs-toggle="pill"
        data-bs-target="#instructions"
        type="button"
        role="tab"
        aria-controls="instructions"
        aria-selected="false"
      >
        Explore Materials
      </button>
      </li>
      <li class="nav-item flex-sm-fill">
        <button
        class="nav-link border-0 text-uppercase font-weight-bold"
        id="test-history-tab"
        data-bs-toggle="pill"
        data-bs-target="#test-history"
        type="button"
        role="tab"
        aria-controls="test-history"
        aria-selected="false"
        @click="generateTable"
      >
        Test History
      </button>
      </li>
      <li class="nav-item flex-sm-fill">
        <button
        class="nav-link border-0 text-uppercase font-weight-bold"
        id="report-tab"
        data-bs-toggle="pill"
        data-bs-target="#report"
        type="button"
        role="tab"
        aria-controls="report"
        aria-selected="false"
      >
        Submit Report
      </button>
      </li>
    </ul>
    <div id="myTabContent" class="tab-content">
      <div id="instructions" role="tabpanel" aria-labelledby="instructions-tab" class="tab-pane fade px-4 py-3 show active">
        <div>Let’s explore the different types of <strong>material</strong> we can use to help the school <strong>minimize</strong> how much water collects. 
        </div>
        <div class="mt-3"><instructions routeName="EE" /></div>
      </div>


      <div id="test-history" role="tabpanel" aria-labelledby="test-history-tab" class="tab-pane fade px-4 py-5">

        <div class="table-el">
          <school-table
            v-if="currentRouteName === 'Playground'"
            :header="designHistoryHeader"
            :contents="playHistory"
            :images="getSimulationImages"
          ></school-table>
          <school-table
            v-else
            :header="designHistoryHeader"
            :contents="designHistory"
            :images="getSimulationImages"
          ></school-table>
          <p><em>*An "accessible" material means that students and teachers in wheelchairs can easily access the area.</em></p>
        </div>
      </div>

      <div id="report" role="tabpanel" aria-labelledby="report-tab" class="tab-pane fade px-4 py-2">

        <form>
          <div class="form-group row mt-3"> 
            <label>If it rains 8cm, how much water runoff will your final design have?</label>
            <textarea class="form-control" aria-label="With textarea" placeholder="Explain your findings..." id="explain-material"></textarea> 
          </div>

          <div class="form-group row mt-3"> 
            <label>How many accessible squares does your final design have?</label>
            <textarea class="form-control" aria-label="With textarea" placeholder="Type your equation here..." id="equation"></textarea> 
          </div>

          <div class="form-group row mt-3"> 
            <label>Please describe your final design for the schoolyard. In your description, explain why you picked the design compared to your other design tests.</label>
            <textarea class="form-control" aria-label="With textarea" placeholder="Explain your reasoning..." id="equation"></textarea> 
          </div>

        
          <a href="/playground" class="btn btn-primary submit-button mt-3">Submit and Move On</a>
        </form>
        
      </div>
    </div>
    <!-- End rounded tabs -->
  </div>



</template>

<script>
/**
 * Display Panel Component
 * Can be integrated with any component that requires design history visualization.
 * @requires ./DesignTable.vue Component to generate a table
 * @requires ../services/Visualize.js currently using Google library to generate charts.
 * @requires ./Compare.vue Component to generate a table of selected designs for comparison.
 */
import visualize from "../services/Visualize";
// import DesignTable from "./DesignTable.vue";
import SchoolTable from "./SchoolTable.vue";
// import Compare from "./Compare.vue";
import Instructions from "./Instructions.vue";

export default {
  name: "EngineeringDisplayPanel",
  components: {
    // DesignTable,
    SchoolTable,
    // Compare,/
    Instructions,
  },
  data() {
    return {
      designHistoryContent: [],
      designHistoryHeader: [
        "design",
        "rainfall",
        // "cost",
        "runoff",
        "absorption",
        "accessible squares",
        // "concrete",
        // "permeable concrete",
        // "grass",
        // "wood chips",
        // "artificial turf",
        // "poured rubber",
      ],
      compareHeader: [
      "Stage",
        "design/date",
        "cost",
        "runoff",
        "accessible squares",
        "rainfall",
        "absorption",
        "concrete",
        "permeable concrete",
        "grass",
        "wood chips",
        "artificial turf",
        "poured rubber",
      ],
      checkedDesignStatus: [],
    };
  },
  computed: {
    /**
     * Get the number of tests run by the user from store.
     */
    historyLength() {
      return this.$store.getters.getdhLength;
    },
    /**
     * Get the entire design history from the store.
     */
    designHistory() {
      return this.$store.getters.getDesignHistory;
    },
    /**
     * Get a list of checkbox status in the design history table from the store.
     */
    getCheckedDesigns() {
      return this.$store.getters.getCheckedDesigns;
    },
    /**
     * Get a list of Stage images from the store.
     */
    getSimulationImages() {
      return this.$store.getters.getStageImages;
    },
    /**
     * Get the status of green flag from store.
     */
    getRunStatus() {
      return this.$store.getters.getSimulationStatus;
    },
    getFavoriteDesigns() {
      return this.$store.getters.getFavoriteDesigns;
    },
    currentRouteName() {
      return this.$route.name;
    },
    playHistory() {
      return this.$store.getters.getPlayHistory;
    },
    getPlayChecks() {
      return this.$store.getters.getPlayChecks;
    },
    getPlayFavs() {
      return this.$store.getters.getPlayFavs;
    },
  },
  methods: {
    /**
     * Generates a table by accessing design history content from c2stem environment.
     * The method gets design history from c2stem and compares the results with the history in the store.
     * The history in the store is updated with new design history from c2stem.
     */
    async generateTable() {
      this.designHistory_content = await visualize.getData();
      if (this.currentRouteName === "Playground") {
        const dhList = [];
        const checkList = [];
        const favList = [];
        Object.values(this.designHistory_content).forEach((element) => {
          dhList.push(element);
          checkList.push(false);
          favList.push(false);
        });
        this.$store.dispatch("addPlayHistory", dhList);
        this.$store.dispatch("addPlayFavorites", favList);
        this.$store.dispatch("addPlayCheckedStatus", checkList);
      } else {
        const dhList = [];
        const checkList = [];
        const favList = [];
        let dhLength = Object.keys(this.designHistory_content).length;
        this.checkedDesignStatus = this.getCheckedDesigns;
        let checkLength = this.checkedDesignStatus.length;
        // let favLength = this.favoriteStatus.length;
        this.favoriteStatus = this.getFavoriteDesigns;
        let stateDhLength = this.historyLength;
        if (checkLength > stateDhLength) {
          if (checkLength === dhLength || checkLength === dhLength - 1) {
            this.checkedDesignStatus.forEach((element) => {
              checkList.push(element);
            });
            this.favoriteStatus.forEach((element) => {
              favList.push(element);
            });
          } else {
            this.$store.dispatch("resetCheckedDesigns");
            this.$store.dispatch("resetFavoriteDesigns");
          }
        }
        if (dhLength > stateDhLength) {
          Object.values(this.designHistory_content).forEach(
            (element, index) => {
              if (index >= stateDhLength && index < dhLength) {
                dhList.push(element);
                checkList.push(false);
                favList.push(false);
              }
            }
          );
          this.$store.dispatch("addDesignHistory", dhList);
          if (this.getFavoriteDesigns.length === 0) {
            this.$store.dispatch("addFavoriteDesigns", favList);
          }
          if (this.getCheckedDesigns.length === 0) {
            this.$store.dispatch("addCheckedDesigns", checkList);
          }
        }
      }
    },
  },
  mounted() {
    /**
     * Load google visualization library
     */
    window.google.charts.load("current", {
      packages: ["table", "corechart", "line"],
    });

    this.emitter.on("update-data", (evt) => {
      if (evt.status) {
        this.generateTable();
      }
    });
  },
};
</script>

<style scoped>
.form-control{
	height: 50px;
	background: #ecf0f4;
	border-color: transparent;
	padding: 0 15px;
	font-size: 16px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.report-button {
  background-color: #008080;
  color: #ffffff; /* White text color */
}

.report-button:hover {
  background-color: #f9c748; /* Yellow background */
  color: #008080; /* White text color */
}

.modal-dialog {
  --bs-modal-width: 100%;
}

</style>
