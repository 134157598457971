<template>
  <!-- Global header -->
  <nav class="navbar sticky-top navbar-expand-lg navbar-dark head">
    <div class="container-fluid">
      <button
        type="button"
        class="btn btn-link navbar-brand"
        @click="returnNav"
      >
        <img src="../assets/datawise.png" alt="" width="60" height="60" />
      </button>
      <div class="h4 ms-2 me-2">DATAWISE Demo Projects</div>
    </div>
  </nav>
</template>

<script>
import Formatter from "../helpers/format.js";
import Simulation from "../services/Simulation";

export default {
  name: "ClassHeader",
  components: {
  },
  computed: {
    loggedIn() {
      return this.$store.state.user;
    },
    currentRole() {
      let role = sessionStorage.getItem("userRole");
      return Formatter.removeQuotes(role);
    },
    currentRouteName() {
      return this.$route.name;
    },
    getProjectName() {
      return sessionStorage.getItem("projectName");
    },
    getCheckedDesigns() {
      return this.$store.getters.getCheckedDesigns;
    },
  },
  methods: {
    async saveProject() {
      try {
        let name = this.getProjectName;
        Simulation.saveToCloud(name);
        // let checkList = this.getCheckedDesigns;
        // let username = this.loggedIn;
        // Simulation.saveHistory(checkList, username);
        // setTimeout(() => {
        //   Simulation.publishProject(name, true);
        // }, 5000);
      } catch (error) {
        console.log("Cannot save the project", error);
      }
    },
    // TO DO: need to be improved with new routes.
    returnNav() {
        this.$router.push("/");
    },
  },
  mounted() {
    this.emitter.on("save-project", (evt) => {
      if (evt.status) {
        Simulation.publishProject(this.getProjectName, true);
      }
    });
  },
};
</script>

<style scoped>
.head {
  background: #12aacc;
  box-shadow: 5px 5px 5px black
}
.btn:focus {
  box-shadow: none;
}
</style>
