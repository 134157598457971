<template>
    <!--  Playground Engineering View-->
    <div class="container">
      <div class="row">
        <div class="col-5 mt-4 pt-4 ">
          <div class="card explore-card">
              
            <div class="iframe-panel mt-2">
              <IframeLoader
              :embed="true"
              projectname="land-testing"
              username="nicole"
              iframeid="iframe-id"
              source="https://editor.c2stem.org"
            ></IframeLoader>
            </div>
            <div class="mx-auto mt-3 mb-4">
              <engineering-simulation-panel :lessonName="name" />
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="right-panel mt-4">
            <div class="p-4 mb-5">
                <!-- Rounded tabs -->
                <ul id="myTab" role="tablist" class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
                <li class="nav-item flex-sm-fill">
                    <button
                    class="nav-link active notebook-tab"
                    id="instructions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#instructions"
                    type="button"
                    role="tab"
                    aria-controls="instructions"
                    aria-selected="false"
                >
                    Instructions
                </button> </li>
                </ul>
                <div id="myTabContent" class="tab-content">
                <div id="instructions" role="tabpanel" aria-labelledby="instructions-tab" class="tab-pane fade px-4 py-3 show active">
                    This activity is currently under construction - with lots of potential opportunities! For instance, students could: 
                    <ul>
                        <li>Minimize flooding for a city that is located near a river at the bottom of the hill,</li>
                        <li>Explore regional design factors (e.g., the number of cities, community centers, farms needed for a population) and aim to minimize flooding while meeting those needs,</li>
                        <li>Examine the role of deforestation on runoff when you add landscapes that require deforestation (city, community center, farm, etc), and</li>
                        <li>so much more!</li>
                    </ul>
                </div>


    </div>
    <!-- End rounded tabs -->
  </div>
          </div>
        </div>
      </div>
      <div
        class="modal show"
        id="loadModal"
        :data-bs-backdrop="backgroundStatus"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div>
                <strong>Loading Project...</strong>
                <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <button
                v-if="projectLoaded"
                type="button"
                class="btn btn-secondary ms-5"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GlobalAdventureMenu />
  </template>
  
  <script>
  import GlobalAdventureMenu from "../../components/GlobalAdventureMenu"
  import EngineeringSimulationPanel from "../../components/EngineeringSimulationPanel.vue";
  import IframeLoader from "../../components/IframeLoader.vue";
  import { Modal } from "bootstrap";
  export default {
    name: "Playground",
    components: {
      EngineeringSimulationPanel,
      IframeLoader,
      GlobalAdventureMenu,
    },
    data() {
      return {
        loadStatus: false,
        background: "static",
      };
    },
    computed: {
      projectLoaded() {
        return this.loadStatus;
      },
      backgroundStatus() {
        return this.background;
      },
    },
    mounted() {
      const iframe = document.getElementById("iframe-id");
      const api = new window.EmbeddedNetsBloxAPI(iframe);
      const myModal = new Modal(document.getElementById("loadModal"));
      myModal.show();
      iframe.onload = () => {
        api.addEventListener("action", (e) => {
          if (e.detail.type === "openProject") {
            this.loadStatus = true;
            myModal.hide();
          }
        });
      };
    },
  };
  </script>
  <style scoped>
  .container {
    max-width: 100%;
    max-height: 80%;
    display: grid;
  }
  div {
    min-height: 0;
  }
  .explore-card {
      background-color: #e3fbf0;
    }
  .iframe-panel {
      /* margin: 0 10px 1px 10px; */
      height: 200px;
      /* border: 3px inset #615195; */
      padding-right: 28px;
      padding-left: 28px;
      padding-top: 6px;
      /* background-color: #000 */
    }
  .card {
    margin: 10px;
  }
  .right-panel {
    height: 70%;
  }
  .notes-panel {
    height: 30%;
    display: flex;
  }
  
  .left-group {
    height: auto;
    display: inline-flex;
    align-items: center;
  }
  .codeBttn {
    height: fit-content;
  }
  .modal-body {
    display: flex;
    justify-content: center;
  }
  .modal-dialog {
    display: flex;
    align-items: center;
  }
  .modal-content {
    background-color: rgba(0, 0, 0, 0.0001) !important;
    border: 0;
  }
  strong {
    font-size: x-large;
    color: aliceblue;
  }
  </style>
  