<template>
  <!-- Display Panel Component -->
  <div class="p-4 mb-5">
    <!-- Rounded tabs -->
    <ul id="myTab" role="tablist" class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
      <li class="nav-item flex-sm-fill">
        <button
        class="nav-link border-0 text-uppercase font-weight-bold active"
        id="instructions-tab"
        data-bs-toggle="pill"
        data-bs-target="#instructions"
        type="button"
        role="tab"
        aria-controls="instructions"
        aria-selected="false"
      >
        Explore Materials
      </button>
      </li>
      <li class="nav-item flex-sm-fill">
        <button
        class="nav-link border-0 text-uppercase font-weight-bold"
        id="test-history-tab"
        data-bs-toggle="pill"
        data-bs-target="#test-history"
        type="button"
        role="tab"
        aria-controls="test-history"
        aria-selected="false"
        @click="generateTable"
      >
        Test History
      </button>
      </li>
      <li class="nav-item flex-sm-fill">
        <button
        class="nav-link border-0 text-uppercase font-weight-bold"
        id="report-tab"
        data-bs-toggle="pill"
        data-bs-target="#report"
        type="button"
        role="tab"
        aria-controls="report"
        aria-selected="false"
      >
        Submit Report
      </button>
      </li>
    </ul>
    <div id="myTabContent" class="tab-content">
      <div id="instructions" role="tabpanel" aria-labelledby="instructions-tab" class="tab-pane fade px-4 py-3 show active">
        <div>Let’s explore the different types of <strong>material</strong> we can use to help the school <strong>minimize</strong> how much water collects. 
        </div>
        <div class="mt-3"><instructions routeName="EE" /></div>
      </div>


      <div id="test-history" role="tabpanel" aria-labelledby="test-history-tab" class="tab-pane fade px-4 py-5">

        <div class="table-el">
          <design-table
            :header="testHistoryHeader"
            :contents="activeTableContent"
          ></design-table>
        </div>
      </div>

      <div id="report" role="tabpanel" aria-labelledby="report-tab" class="tab-pane fade px-4 py-2">

        <form>
          <div class="row">
          <label>Which materials are the <strong>best</strong> for absorption? (Pick two)</label>
            <div class="form-group mt-3">
                    
              <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="grass" id="grass" value="grass">
                  <label class="form-check-label" for="grass">Grass</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="wood" id="wood" value="wood">
                  <label class="form-check-label" for="wood">Wood</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="concrete" name="concrete" value="concrete">
                  <label class="form-check-label" for="concrete">Concrete</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="permeable" name="permeable" value="permeable">
                  <label class="form-check-label" for="permeable">Perm. Concrete</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="turf" name="turf" value="turf">
                  <label class="form-check-label" for="turf"> Turf</label>
                </div> 
                
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="rubber" name="rubber" value="rubber">
                  <label class="form-check-label" for="rubber"> Rubber</label>
                </div> 
                
            </div>
          </div>
            <!-- end of row -->
            <div class="row mt-3">
              <label>Which materials are the <strong>worst</strong> for absorption? (Pick two)</label>
              <div class="form-group mt-3">
                      
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="grass" id="grass" value="grass">
                  <label class="form-check-label" for="grass">Grass</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="wood" id="wood" value="wood">
                  <label class="form-check-label" for="wood">Wood</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="concrete" name="concrete" value="concrete">
                  <label class="form-check-label" for="concrete">Concrete</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="permeable" name="permeable" value="permeable">
                  <label class="form-check-label" for="permeable">Perm. Concrete</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="turf" name="turf" value="turf">
                  <label class="form-check-label" for="turf"> Turf</label>
                </div> 
                
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="rubber" name="rubber" value="rubber">
                  <label class="form-check-label" for="rubber"> Rubber</label>
                </div> 
                  
              </div>
            </div>
          <!-- end of row -->

          <div class="form-group row mt-3"> 
            <label>Explain why you selected those materials for the best and worst absorbing.</label>
            <textarea class="form-control" aria-label="With textarea" placeholder="Explain your findings..." id="explain-material"></textarea> 
          </div>

          <div class="form-group row mt-3"> 
              <label>Arrange the items to form the correct equation for water runoff when rainfall is greater than the absorption limit of the surface material:</label>
            <div
              class="drag-container"
              @dragover.prevent
              @drop="onDrop($event, 'source')"
            >
              <div
                class="draggable-item"
                v-for="item in draggableItems"
                :key="item"
                draggable="true"
                @dragstart="onDragStart($event, item, 'source')"
                :data-item="item"
                :style="{ backgroundColor: getItemColor(item) }"
              >{{ item }}</div>
            </div>
            <div
              class="dropzone"
              @dragover.prevent
              @drop="onDrop($event, 'dropzone')"
            >
              <div
                v-for="item in droppedItems"
                :key="item"
                class="draggable-item"
                draggable="true"
                @dragstart="onDragStart($event, item, 'dropzone')"
                :data-item="item"
                :style="{ backgroundColor: getItemColor(item) }"
              >{{ item }}</div>
            </div>
          </div>

          <div class="form-group row mt-3"> 
            <label>What kind of materials should we use for the schoolyard that both kids and teachers would like? Why do you think these are good choices?</label>
            <textarea class="form-control" aria-label="With textarea" placeholder="Explain your reasoning..." id="equation"></textarea> 
          </div>

        
          <a href="/playground" class="btn btn-primary submit-button mt-3">Submit and Move On</a>
        </form>
        
      </div>
    </div>
    <!-- End rounded tabs -->
  </div>

</template>

<script>
/**
 * Display Panel Component
 * Can be integrated with any component that requires design history visualization.
 * @requires ./DesignTable.vue Component to generate a table
 * @requires ../services/Visualize.js currently using Google library to generate charts.
 */
import visualize from "../services/Visualize";
import DesignTable from "./DesignTable.vue";
import Instructions from "./Instructions.vue";

export default {
  name: "DisplayPanel",
  components: {
    DesignTable,
    Instructions,
  },
  data() {
    return {
      testHistoryHeader: [
        "Test #",
        // "Date",
        "Rainfall (cm)",
        "Surface Material",
        // "Absorption limit (cm)",
        "Absorption (cm)",
        "Runoff (cm)",
      ],
      activeTableContent: this.testHistory,
      draggableItems: ["runoff", "rainfall", "absorption", "=", "+", "-"],
      droppedItems: []
    };
  },
  computed: {
    /**
     * Get the number of tests run by the user from store.
     */
    testHistoryLength() {
      return this.$store.getters.getthLength;
    },
    /**
     * Get the entire test history from the store.
     */
    testHistory() {
      return this.$store.getters.getTestHistory;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    /**
     * Generates a table by accessing test history content from c2stem environment.
     * The method gets test history from c2stem and compares the results with the history in the store.
     * The history in the store is updated with new test history from c2stem.
     */
    async generateTable() {
      if (this.currentRouteName === "Explore Materials") {
        this.testHistoryContent = await visualize.getTestData("explore");
        this.activeTableContent = this.testHistoryContent;
        this.$store.dispatch("addEETestHistory", this.testHistoryContent);
      } 
      else {
        this.activeTableContent = this.testHistory;
        this.testHistoryContent = await visualize.getTestData("explore");
        let thLength = Object.keys(this.testHistoryContent).length;
        let stateThLength = this.testHistoryLength;
        if (thLength > stateThLength) {
          const thList = [];
          Object.values(this.testHistoryContent).forEach((element, index) => {
            if (index >= stateThLength && index < thLength) {
              thList.push(element);
            }
          });
          this.$store.dispatch("addTestHistory", thList);
        }
      }
    },
    onDragStart(event, item, zone) {
      event.dataTransfer.setData("text/plain", item);
      event.dataTransfer.setData("zone", zone);
      event.dataTransfer.setData("index", this.draggableItems.indexOf(item));
    },
    onDrop(event, zone) {
      const item = event.dataTransfer.getData("text/plain");
      const sourceZone = event.dataTransfer.getData("zone");

      if (sourceZone === zone) {
        return;
      }

      if (zone === "dropzone") {
        if (!this.droppedItems.includes(item)) {
          this.droppedItems.push(item);
        }
        this.draggableItems = this.draggableItems.filter(i => i !== item);
      } else {
        if (!this.draggableItems.includes(item)) {
          this.draggableItems.push(item);
        }
        this.droppedItems = this.droppedItems.filter(i => i !== item);
      }

      event.dataTransfer.clearData();
    },
    getItemColor(item) {
      const colors = {
        "runoff": "#FFCDD2",
        "rainfall": "#BBDEFB",
        "absorption": "#C8E6C9",
        "=": "#D1C4E9",
        "+": "#D1C4E9",
        "-": "#D1C4E9"
      };
      return colors[item] || "#f9f9f9";
    },
  },
  mounted() {
    this.emitter.on("update-data", (evt) => {
      if (evt.status) {
        this.generateTable();
      }
    });
  },
};
</script>

<style scoped>

.form-control{
	height: 50px;
	background: #ecf0f4;
	border-color: transparent;
	padding: 0 15px;
	font-size: 16px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.report-button {
  background-color: #008080;
  color: #ffffff; /* White text color */
}

.report-button:hover {
  background-color: #f9c748; /* Yellow background */
  color: #008080; /* White text color */
}
.drag-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 50px;
}
.draggable-item {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: grab;
  width: 100px;
  text-align: center;
  margin: 5px;
  border-radius: 10px; /* Curved edges */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); /* Drop shadow */
}
.draggable-item:active {
  cursor: grabbing;
}
.dropzone {
  min-height: 50px;
  padding: 10px;
  border: 2px dashed #ccc;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
}
.dragging {
  opacity: 0.5;
}
</style>
