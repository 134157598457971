<template>
  <nav class="nav nav-pills nav-justified sequence-navbar">
    <a class="nav-item nav-link"
       :class="{ 'active': isActive('/school-intro') }"
       href="/school-intro">Understanding the Problem →</a>
    <a class="nav-item nav-link"
       :class="{ 'active': isActive('/explore-materials') }"
       href="/explore-materials">Explore Materials →</a>
    <a class="nav-item nav-link"
       :class="{ 'active': isActive('/compare-test') }"
       href="/compare-test">Testing Runoff Solutions →</a>
    <!-- <a class="nav-item nav-link"
       :class="{ 'active': isActive('#') }"
       href="#">Presenting Our Findings ⏹</a> -->
  </nav>
</template>

<script>
export default {
  methods: {
    isActive(path) {
      return this.$route.path === path;
    }
  }
}
</script>

<style scoped>
.sequence-navbar {
 position: fixed;
 bottom: 0;
 width: 100%;
 background-color: #12aacc; /* dark background color */
 box-shadow: 1px -5px 4px black
}

.sequence-navbar .nav-link {
 color: #fff; /* text color */
 background-color: #12aacc; /* grey background color for inactive links */
 border-radius: 0;
 transition: background-color 0.3s; /* smooth transition */
}

.sequence-navbar .nav-link.active {
 background-color: #007bff; /* teal background color for active link */
}

.sequence-navbar .nav-link.active::before {
 content: '';
 position: absolute;
 top: 50%;
 left: -20px;
 width: 0;
 height: 0;
 border-top: 10px solid transparent;
 border-bottom: 10px solid transparent;
 border-right: 10px solid #007bff; /* teal color */
 transform: translateY(-50%);
}

.sequence-navbar .nav-link:not(:last-child)::after {
 content: '';
 position: absolute;
 top: calc(50% - 1px);
 left: 100%;
 width: 20px;
 height: 2px;
 background-color: #fff; /* white color */
}

.sequence-navbar .nav-link:last-child::after {
 content: url('https://image.flaticon.com/icons/svg/860/860828.svg');
 position: absolute;
 top: 50%;
 left: calc(100% + 10px);
 transform: translateY(-50%);
 width: 20px;
 height: 20px;
}

.sequence-navbar .nav-link:hover {
 background-color: #fd7e14; /* orange color on hover */
}


</style>