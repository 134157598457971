<template>
   <nav class="nav nav-pills nav-justified sequence-navbar">
    <a class="nav-item nav-link" href="/intro-runoff">Understanding the Problem</a>
    <a class="nav-item nav-link" href="/explore-runoff">Exploring Water Runoff</a>
    <a class="nav-item nav-link" id="debug" href="/debug">Creating Our Model</a>
    <a class="nav-item nav-link" href="/playground">Testing Runoff Solutions</a>
    <a class="nav-item nav-link" href="#">Presenting Our Findings</a>
</nav>
</template>

<script>

</script>

<style scoped>
.sequence-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333; /* dark background color */
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* drop shadow */
}

.sequence-navbar .nav-link {
  color: #fff; /* text color */
  background-color: #6c757d; /* grey background color for inactive links */
  border-radius: 0;
  transition: background-color 0.3s; /* smooth transition */
}

.sequence-navbar .nav-link.active {
  background-color: #007bff; /* teal background color for active link */
}

.sequence-navbar .nav-link.active::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -20px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #007bff; /* teal color */
  transform: translateY(-50%);
}

.sequence-navbar .nav-link:not(:last-child)::after {
  content: '';
  position: absolute;
  top: calc(50% - 1px);
  left: 100%;
  width: 20px;
  height: 2px;
  background-color: #fff; /* white color */
}

.sequence-navbar .nav-link:last-child::after {
  content: url('https://image.flaticon.com/icons/svg/860/860828.svg');
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.sequence-navbar .nav-link:hover {
  background-color: #fd7e14; /* orange color on hover */
}


</style>