<template>
  <div class="container">
      <h2 class="mt-4">Floodwise: Understanding Water Runoff Impact</h2>
      <div class="row mt-3">
          <div class="col-4">
              <div id="carouselExampleCaptions" class="carousel slide">
                  <div class="carousel-indicators">
                      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div class="carousel-inner shadow p-3 mb-5 bg-body rounded">
                      <div class="carousel-item active">
                          <img src="@/assets/runoff/rain-thai.png" class="d-block w-100" alt="...">
                          <div class="carousel-caption d-none d-md-block">
                          <!-- <h5>Rain</h5> -->
                          <p class="fw-semibold text-dark bg-white">Heavy rains can greatly impact our daily lives...</p>
                          </div>
                      </div>
                      <div class="carousel-item">
                          <img src="@/assets/runoff/flood-thai.png" class="d-block w-100" alt="...">
                          <div class="carousel-caption d-none d-md-block">
                          <!-- <h5>Runoff</h5> -->
                          <p class="fw-semibold text-dark bg-white">... creating water runoff and floods in our community...</p>
                          </div>
                      </div>
                      <div class="carousel-item">
                          <img src="@/assets/runoff/after-thai.png" class="d-block w-100" alt="...">
                          <div class="carousel-caption d-none d-md-block">
                          <!-- <h5>Results</h5> -->
                          <p class="fw-semibold text-dark bg-white">... that can damage our buildings and carry pollution that is harmful to the environment.</p>
                      </div>
                  </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
              </button>
          </div>
      </div>
      <div class="col-8">
          <div class="card shadow border-0">
              <div class="card-body">
                <div class="">
                        <strong>Your Mission:</strong> Become an engineer and solve a flooding problem in your region!
                        <br />
                        <br />

                        <strong>The Problem:</strong> During the rainiest months of the year, communities around Thailand often experience flooding problems. 
                        The more it rains, the more it floods. This can disrupt our daily lives and even damage our businesses, farms, and schools.
                        <br />
                        <br />
                    
                        <strong>The Plan:</strong> To help them, we are going to explore different landscapes and dive deeper into issues that may cause more flooding. To do so, you will:
                        <br />
                        <br />
                
                        <ul>
                            <li><strong>Use math</strong> to calculate runoff</li> 
                            <li><strong>Observe</strong>  how water behaves on surfaces made of different landscapes</li>
                            <li><strong>Test</strong>  regional designs for communities that can help to address the problem, and</li>
                            <li><strong>Present</strong>  your final design!</li>
                        </ul>
                        <strong>Remember:</strong> Think about what what your community would like and need. The schoolyard should be safe, fun and welcoming!
                <br />
                </div>
          </div>
          </div>
      </div>    
  </div>
  <div class="row mt-2">
      <div class="card border-0">
          <div class="card-body intro-card rounded">
              <h5 class="text-white">Connecting to the Problem</h5>
          <form class="mt-4">
              <div class="text-white">How does rainfall impact you?</div>
              <textarea class="form-control" aria-label="With textarea" placeholder="Explain your findings"></textarea>
              <br />
      
              <div class="text-white">Why do you think it's important to investigate water runoff in your local community?</div>
                  <textarea class="form-control" aria-label="With textarea" placeholder="Explain your findings"></textarea>
                  <br />
                  <a href="/explore-land" class="btn btn-primary">Submit and Move On</a>
              </form>
          </div>
          </div>
      </div>
  </div>
    <global-adventure-menu />
</template>

<script>

import GlobalAdventureMenu from "../../components/GlobalAdventureMenu.vue";


export default {
  name: "Instructions",
  components: {
    GlobalAdventureMenu,
  },
};
</script>

<style>
</style>