import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/LandingPage.vue";
import PageNotFound from "../views/PageNotFound.vue";

import LocalAdventure from "../views/local/LocalAdventure.vue";
import IntroSchoolFlooding from "../views/local/IntroSchoolFlooding.vue";
import ExploreMaterials from "../views/local/ExploreMaterials.vue";
import CompareTest from "../views/local/CompareTest.vue";

import IntroRunoff from "../views/global/IntroRunoff.vue";
import ExploreLandPlots from "../views/global/ExploreLandPlots.vue"
import ExploringRunoff from "../views/ExploringWaterRunoff.vue";
import RegionalDesign from "../views/global/RegionalDesign.vue"
import PollutionDemo from "../views/PollutionDemo.vue"
import Coding from "../views/Coding.vue";
import EngineeringDesign from "../views/EngineeringDesign.vue";
import DebugMaterials from "../views/DebugMaterials.vue";


const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: { title: "DATAWISE | Landing" },
  },
  {
    path: "/local-menu",
    name: "Local Adventure Menu",
    component: LocalAdventure,
    meta: { title: "DATAWISE | Local Adventure" },
  },
  {
    path: "/school-intro",
    name: "School Intro",
    component: IntroSchoolFlooding,
    meta: { title: "DATAWISE | Introduction to School Flooding" },
  },
  {
    path: "/explore-materials",
    name: "Explore Materials",
    component: ExploreMaterials,
    meta: { title: "DATAWISE | Calculating Water Runoff" },
  },
  {
    path: "/compare-test",
    name: "Compare",
    component: CompareTest,
  },
  {
    path: "/intro-runoff",
    name: "Intro Runoff",
    component: IntroRunoff,
    meta: { title: "DATAWISE | Introduction to the Runoff Problem" },
  },
  {
    path: "/explore-land",
    name: "Explore Land",
    component: ExploreLandPlots,
    meta: { title: "DATAWISE | Explore Water Runoff on Different Landscapes" },
  },
  {
    path: "/regional-design",
    name: "Regional Design",
    component: RegionalDesign,
    meta: { title: "DATAWISE | Regional Design Tests" },
  },
  {
    path: "/pollution",
    name: "Pollution",
    component: PollutionDemo,
    meta: { title: "DATAWISE | Pollution Demo" },
  },
  {
    path: "/coding",
    name: "Coding",
    component: Coding,
    meta: { title: "DATAWISE | Coding" },
  },
  {
    path: "/engineering",
    name: "Engineering",
    component: EngineeringDesign,
    meta: { title: "DATAWISE | Engineering" },
  },
  {
    path: "/debug",
    name: "Debug",
    component: DebugMaterials,
    meta: { title: "DATAWISE | Debug" },
  },
  {
    path: "/explore-runoff",
    name: "Explore Runoff",
    component: ExploringRunoff,
    meta: { title: "DATAWISE | Exploring Water Runoff in Our Community" },
  },
  {
    path: "/:NotFound(.*)",
    name: "PageNotFound",
    component: PageNotFound,
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
