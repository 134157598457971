<template>
    <div class="container">
        <h2 class="mt-4">Flooding Solutions: School Edition</h2>
        <div class="row mt-3">
            <div class="col-4">
                <div id="carouselExampleCaptions" class="carousel slide">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner shadow p-3 mb-5 bg-body rounded">
                        <div class="carousel-item active">
                            <img src="@/assets/runoff/thai-school-rain.png" class="d-block w-100" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                            <!-- <h5>Rain</h5> -->
                            <p class="fw-semibold text-dark bg-white">Heavy rains can greatly impact our daily lives...</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="@/assets/runoff/thai-school-flood.png" class="d-block w-100" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                            <!-- <h5>Runoff</h5> -->
                            <p class="fw-semibold text-dark bg-white">... creating water runoff and floods in our school...</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="@/assets/runoff/thai-school-after.png" class="d-block w-100" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                            <!-- <h5>Results</h5> -->
                            <p class="fw-semibold text-dark bg-white">... that can damage our buildings and carry pollution that is harmful to the environment.</p>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <div class="col-8">
            <div class="card shadow border-0 ">
                <div class="card-body">
                    <div class="">
                        <strong>Your Mission:</strong> Become an engineer and solve a flooding problem at a school in Thailand!
                        <br />
                        <br />

                        <strong>The Problem:</strong> The more it rains, the more water flows down and collects in the schoolyard. This can disrupt school activities and even damage school buildings.
                        <br />
                        <br />
                    
                        <strong>The Plan:</strong> To help them, we will redesign their schoolyard to minimize the amount of water that flows down (or water “runoff”). To do so, you will:
                        <br />
                        <br />
                
                        <ul>
                            <li><strong>Use math</strong> to calculate runoff</li> 
                            <li><strong>Observe</strong>  how water behaves on surfaces made of different types of material</li>
                            <li><strong>Test</strong>  different designs for the schoolyard to see which one minimizes water runoff, and</li>
                            <li><strong>Present</strong>  your final design!</li>
                        </ul>
                        <strong>Remember:</strong> Think about what students and teachers would like. The schoolyard should be fun and welcoming!
                <br />
                </div>
                </div>
            </div>
        </div>    
    </div>
    <div class="row mt-2">
        <div class="card border-0">
            <div class="card-body intro-card rounded">
                <h5 class="text-white">Connecting to the Problem</h5>
            <form class="mt-4">
                <div class="text-white">How does rainfall impact you?</div>
                <textarea class="form-control" aria-label="With textarea" placeholder="Explain using examples..."></textarea>
                <br />
        
                <div class="text-white">Why do you think it's important to investigate water runoff in your local community?</div>
                    <textarea class="form-control" aria-label="With textarea" placeholder="Explain your reasoning..."></textarea>
                    <br />
                    <a href="/explore-materials" class="btn btn-primary">Submit and Move On</a>
                </form>
            </div>
            </div>
        </div>
    </div>
      <local-adventure-menu />
  </template>
  
  <script>
  
  import LocalAdventureMenu from "../../components/LocalAdventureMenu.vue";
  
  
  export default {
    name: "Instructions",
    components: {
      LocalAdventureMenu,
    },
  };
  </script>
  
  <style>
  .intro-card {
    background: #12aacc;
}

.container {
  padding-bottom: 50px; /* Adjust the value as needed */
}

.card-body {
    text-align: left;
}

.report-button {
  background-color: #939393; /* Yellow background */
  border: 1px solid #939393; /* Yellow border */
  color: #ffffff; /* White text color */
  border-radius: 5px; /* Rounded corners */
}

.report-button.active {
  background-color: #12aacc; /* Orange background for active tab */
  border-color: #12aacc; /* Orange border for active tab */
}

.report-button:hover {
  background-color: #f27d21; /* Orange background on hover */
}
  </style>