import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createApp } from "vue";
import mitt from "mitt";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axiosInterceptors from "./services/AxiosInterceptors";
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import VueDraggable from "vue-draggable";

const emitter = mitt();
const app = createApp(App);
axiosInterceptors();
app.config.globalProperties.emitter = emitter;
app.use(store).use(router);
// app.use(VueDraggable);
app.mount("#app");

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


