<template>
    <div class="container">
        <h3 class="mt-4">Exploring Water Runoff in Our Community</h3>
        <div class="row mt-3">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="How much did it rain in cm?" aria-label="rain-amount" aria-describedby="basic-addon2">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary test-button" type="button">Test</button>
                </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="card" style="width: 12rem;">
                            <img src="../assets/landscapes/forest.png" class="card-img-top" alt="forest">
                            <div class="card-body">
                                <h6 class="card-title">Forest</h6>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">An item</li>
                                <li class="list-group-item">A second item</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card" style="width: 12rem;">
                            <img src="../assets/landscapes/grassland.png" class="card-img-top" alt="grassland">
                            <div class="card-body">
                                <h6 class="card-title">Grassland</h6>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Absorption: </li>
                                <li class="list-group-item">Runoff: </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card" style="width: 12rem;">
                            <img src="../assets/landscapes/city.png" class="card-img-top" alt="city">
                            <div class="card-body">
                                <h6 class="card-title">City</h6>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Absorption: </li>
                                <li class="list-group-item">Runoff: </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card" style="width: 12rem;">
                            <img src="../assets/landscapes/wetland.png" class="card-img-top" alt="wetland">
                            <div class="card-body">
                                <h6 class="card-title">Wetland / Delta</h6>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Absorption: </li>
                                <li class="list-group-item">Runoff: </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card" style="width: 12rem;">
                            <img src="../assets/landscapes/community.png" class="card-img-top" alt="community">
                            <div class="card-body">
                                <h6 class="card-title">Community Center</h6>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Absorption: </li>
                                <li class="list-group-item">Runoff: </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card" style="width: 12rem;">
                            <img src="../assets/landscapes/farm.png" class="card-img-top" alt="farm">
                            <div class="card-body">
                                <h6 class="card-title">Farm</h6>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Absorption: </li>
                                <li class="list-group-item">Runoff: </li>
                            </ul>
                        </div>
                    </div>
                </div>

            <div class="row mt-5">
                <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active notebook-tab" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Experiment Notebook</button>
                    <button class="nav-link notebook-tab" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Data Analysis</button>
                    <button class="nav-link notebook-tab" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Predictions</button>
                </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active notebook-content" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                    Let's run a few tests to compare how much each plot of land can absorb.
                    You can conduct a test by typing in how much it rained in centimeters at the top. Keep your rainfall amount between 1-10cm.
                    In this section, you should:
                    <ul>
                        <li>Determine if each plot of land can absorb the same amount of rainfall.</li>
                        <li>Develop an equation to calculate water runoff based on what you know about the land and how much it rains.</li>
                        <li>Identify which plots of land are best for minimizing water runoff.</li>
                        <li>Make a prediction: what happens if a region is made up of a lot of city plots? farm plots? grassland? Also discuss how this might impact someone's daily life.</li>
                    </ul>
                </div>
                <div class="tab-pane fade notebook-content" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                    <h5>Rainfall Amount: 4</h5>
                    <canvas id="stacked-bar-chart"></canvas>
                </div>
                <div class="tab-pane fade notebook-content" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
                    <div class="notebook-content">
                        <form>
                        <p>Does each plot of land absorb the same amount of water no matter how much it rains?
                        <input type="radio" id="one" value="One" v-model="picked" />
                        <label for="one">Yes</label>

                        <input type="radio" id="two" value="Two" v-model="picked" />
                        <label for="two">No</label></p>
                            <br />
                        <p>Why do you think that is?</p>
                        <textarea class="form-control" aria-label="With textarea" placeholder="Explain your findings"></textarea>
                        <br />

                        <p>If you know how much it rains and you know how much the land can absorb, can you create an equation that calculates runoff?</p>
                        <p><input type="text" required></p>
                        <br />

                        <p>Make a prediction: what happens if a region is made up of a lot of city plots? farm plots? grassland? Also discuss how this might impact someone's daily life.</p>
                        <textarea class="form-control" aria-label="With textarea" placeholder="Explain your findings"></textarea>
                        <br />
                        <button class="btn report-button" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <RunoffSequenceMenu />
</template>

<script>
import RunoffSequenceMenu from "../components/RunoffSequenceMenu";
import Chart from 'chart.js/auto';

export default {
    name: "Exploring Runoff",
    data() {
        return {
            value: 5
        }
    },
    components: {
    RunoffSequenceMenu,
  },
  mounted() {
  const ctx = document.getElementById('stacked-bar-chart').getContext('2d');
  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ['Forest', 'Grassland', 'City', 'Wetland', 'Community Center', 'Farm'],
      datasets: [
        {
          label: 'Absorption',
          data: [4, 3.5, 1, 3.8, 2, 3],
          backgroundColor: 'rgba(255, 99, 132, 0.5)' // Adjust colors as needed
        },
        {
          label: 'Runoff',
          data: [0, 0.5, 3, 0.2, 2, 1],
          backgroundColor: 'rgba(54, 162, 235, 0.5)' // Adjust colors as needed
        }
      ]
    },
    options: {
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      }
    }
  });
}

}
</script>

<style scoped>
.test-button {
  background-color: #008080;
  color: #ffffff; /* White text color */
}

.test-button:hover {
  background-color: #f9c748; /* Yellow background */
  color: #008080; /* White text color */

}

.notebook-tab {
  background-color: #939393; /* Yellow background */
  border: 1px solid #939393; /* Yellow border */
  color: #ffffff; /* White text color */
  border-radius: 5px; /* Rounded corners */
}

.notebook-tab.active {
  background-color: #12aacc; /* Orange background for active tab */
  border-color: #12aacc; /* Orange border for active tab */
}

.notebook-tab:hover {
  background-color: #f27d21; /* Orange background on hover */
}

.notebook-content {
  background-color: #ffffff; /* White background for tab content */
  padding: 20px; /* Add padding to tab content */
  border: 1px solid #12aacc; /* Yellow border for tab content */
  border-radius: 5px; /* Rounded corners */
}

.container {
  padding-bottom: 20px; /* Adjust the value as needed */
}
</style>